@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,300;1,14..32,300&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e1c1a;
  color: #fff;

  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after{
  box-sizing: border-box;
}

*{
  margin: 0;
  padding: 0;
}

ul[role='list'], ol[role='list']{
  list-style: none;
}

html:focus-within{
  scroll-behavior: smooth;
}

a:not([class]){
  text-decoration-skip-ink: auto;
}

img, picture, svg, video, canvas{
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
}

input, button, textarea, select{
  font: inherit;
}

@media (prefers-reduced-motion: reduce){
  html:focus-within {
    scroll-behavior: auto;
  }
  *, *::before, *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}

body, html{
  height: 100%;
  scroll-behavior: smooth;
}
