.app-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.sangre-logo {
  width: 70px;
  height: 70px;
}

a {
  color: inherit;
  text-decoration: none;
}
h1 {
  font-size: 30px;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
  span {
    display: block;
    color: #ffd200;
  }
}

.arrow {
  margin-left: 0;
  transition: margin-left .3s ease-in-out;
}

.footer:hover .arrow {
  margin-left: 50px;
}


@media only screen and (min-width: 500px) {
  .app-container {
    padding: 40px;
  }
  h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 800px) {
  .app-container {
    padding: 80px 60px;
  }

  .sangre-logo {
    width: 80px;
    height: 80px;
  }

  h1 {
    font-size: 70px;
    margin-bottom: 30px;
    line-height: 1.05;
  }
}

@media only screen and (min-width: 1000px) {
  .app-container {
    padding: 100px 70px;
  }

  h1 {
    font-size: 90px;
    margin-bottom: 40px;
  }
}


.grid-bg {
  background-color: #1e1c1a;
  position: relative;
  background-size: 42px 42px;
  background-position: top right;
  background-image: linear-gradient(to right, #242220 1px, transparent 1px), linear-gradient(to bottom, #242220 1px, transparent 1px);
}